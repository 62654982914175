import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import "react-placeholder/lib/reactPlaceholder.css";
import { TextBlock } from 'react-placeholder/lib/placeholders';

class CatListPlaceholder extends Component {

  render() {
    
    return (
    <div className="pulsing py-3">
      <Row>
        <Col md="4">
          <TextBlock color='#696969' rows={2} />
        </Col>
      </Row>
      <Row>
        {
          [...Array(this.props.videos)].map((x, i) =>
          <Col md="3" key={i} className="py-3">
            <div className="placeholder-video-wrapper">
              <div className="video-placeholder">
              </div>
            </div>
          </Col>
          )
        }
      </Row>
    </div>
    )
  }
}

export default CatListPlaceholder;