import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import CountUp from 'react-countup';
import Fade from 'react-reveal/Fade';
import './index.css';

const DEFAULT_STATE = {
}

class StatsCounter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_STATE
        }
    }

    render() {
        return (
            <Fade left>
                <Row>
                    {/* mobile */}
                    <Col md="12" sm="12" xs="12" className="videos-counter align-self-left px-sm-0 px-md-2 d-block d-sm-none" >
                        <Row>
                            <Col md="6" sm="6" xs="6" >
                                <div className="h2-fluid text-right" style={{ color: "#FA822C" }}>
                                    {this.props.number &&
                                        <CountUp
                                            end={Number(this.props.number)}
                                            duration={3}
                                            separator=" "
                                        />
                                    }
                                </div>
                            </Col>
                            <Col md="6" sm="6" xs="6" className="">
                                <div className="p-fluid text-left" style={{ color: "whitesmoke" }}>{this.props.message}</div>
                            </Col>
                        </Row>
                    </Col>

                    {/* au dessus de mobile */}
                    <Col md="12" sm="12" xs="12" className="videos-counter align-self-left px-sm-0 px-md-2 d-none d-sm-block" >
                        <Row>
                            <Col md="12" sm="12" xs="6" >
                                <div className="h2-fluid text-center bounty" style={{ color: "#FA822C" }}>
                                    {this.props.number &&
                                        <CountUp
                                            end={Number(this.props.number)}
                                            duration={3}
                                            separator=" "
                                        />
                                    }
                                </div>
                            </Col>
                            <Col md="12" sm="12" xs="6" className="mb-2">
                                <div className="p-fluid text-center" style={{ color: "whitesmoke" }}>{this.props.message}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fade>
        )
    }
}

export default StatsCounter;