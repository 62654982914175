import React, { Component } from 'react';

import './index.css';
import DiagonalSection from '../../components/DiagonalSection';

const config = {
  orientation: "right",
  title: "Production",
  src: `public/static/prod_section.jpg`,
  identifier: "section--production",
  bulletPoints: [
    {
      content: {
        icon: "experience.png",
        before: "Mobiliser",
        loop: ["expérience", "savoir-faire"],
        after: "pour produire des images de qualité",
      }
    },
    {
      content: {
        icon: "budget.png",
        before: "Respecter les",
        loop: ["délais", "budgets"],
        after: "",
      }
    },
    {
      content: {
        icon: "encadrer.png",
        before: "Encadrer les équipes",
        loop: ["techniques", "artistiques"],
        after: "",
      }
    },
    {
      content: {
        icon: "projets.png",
        before: "Gérer des projets variés :",
        loop: ["associatifs", "ONG", "Institutions", "Ministères", "PME", "Start-Up", "Entreprises internationales"],
        after: "en France et à l'international",
      }
    },
  ],  
  delimiter: {
    top: {
      present: true,
      style: "reverse",
    },
    bottom: {
      present: false,
    }
  },
};

class Production extends Component {
  render() { 
    return (
      <DiagonalSection config={config} isDiag/>
    )
  }
}

export default Production;






