import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import TextLoader from '../TextLoader';
import Jump from 'react-reveal/Jump';

import './index.css';
import config from '../../config.json';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DEFAULT_STATE = {
    source_paris: BASE_URL + "public/static/Paris4.png",
    source_team: BASE_URL + "public/static/team.png"
}

class ContactCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_STATE
        }
    }

    render() {
        return (
            <Row className="phone-section mt-3">
                <Col md="12" sm="12" className="footer-section text-center text-white">
                    <div className="p-fluid pt-3 pb-3 text-center">
                        {config.contact.answer}
                        <br />
                        {config.contact.au}
                        <span style={{ color: "#fcaa6e", zIndex: "555" }}>
                            <Jump>
                                <TextLoader 
                                    default={config.contact.phone}
                                    identifier="contact-phone"
                                />
                            </Jump>
                        </span>
                    </div>
                </Col>
            </Row>
        )
    }

}

export default ContactCard;