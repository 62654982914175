import React, { Component } from 'react';
import LinkSection from '../../components/LinkSection';
import FirstVideoFromCats from '../../components/FirstVideoFromCats';
import Delimiter from '../../components/Delimiter';
// import { Container, Button } from 'reactstrap';

import './index.css';

class VideoSection extends Component {
  render() {
    return (
      <div id="video-section" style={{ backgroundColor: "#07080F" }}>
        <Delimiter/>
        <FirstVideoFromCats/>
        <LinkSection to="/videos" label="voir plus de vidéos"/>
        {/* <Container style={{backgroundColor: ""}} fluid={true}>
          <div className="py-4 mx-auto" style={{ verticalAlign: "center"}}>
            <div className="text-center">
              <Button outline color="warning" size="lg" className="orange-outline">
                <a 
                  href="https://www.youtube.com/user/lamandarine" 
                  style={{color: "inherit", textDecoration: "none"}}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>Voir plus de vidéos</span>  
                </a>
              </Button>
            </div>
          </div>
      </Container> */}
      </div>
    )
  }
}

export default VideoSection;






