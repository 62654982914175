import React, { Component } from 'react';
import Landing from '../Landing';

import Slide from 'react-reveal/Slide';

import './index.css';

// const text = 'votre partenaire créatif qui traduit vos idées en film';

class LandingSection extends Component {
  render() {  
    return (
      <Slide bottom>
        <Landing/>
      </Slide>
    )
  }
}

export default LandingSection;





