import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import VideoPlayer from '../VideoPlayer';

import './index.css';

class VideoHighlight extends Component {

  render() {

    if (this.props.video) {
      const { video } = this.props;
      return (
        <Row id="video-highlight" className="pt-4 pb-2 pt-md-5 pb-md-3" style={{ paddingLeft: "15px", paddingRight: "15px"}}>
          <Col md={8} className="py-2">
            <VideoPlayer video={video}/>
          </Col>
          <Col md={4} className="highlight-infos py-md-2 d-flex">
            <div className="pb-2 align-self-center" style={{maxWidth: "100%"}}>
              <div className="highlight-title h3-fluid">{video.title}</div>
              {/* <p className="p-light">Mise en ligne le {video.createdAt}.</p> */}
              <hr className="hr-orange"/>
              <div className="mb-0 p-fluid text-white">{video.description}</div>
            </div>
          </Col>
        </Row>
      )
    } else return null;
  }
}

export default VideoHighlight;






