import React, { Component } from 'react';
import { withContext } from '../../context';

class ImageLoader extends Component { 
  render() {
    const { ctx, identifier } = this.props
    const path = ctx.images.length > 0 ?
                    ctx.images.find(i => i.identifier === identifier) ?
                        ctx.images.find(i => i.identifier === identifier).path
                      :
                        this.props.default
                  : 
                    this.props.default
    return (
      <img
        src={`${process.env.REACT_APP_BASE_URL}${path}`}
        alt={this.props.alt}
        className={this.props.classes}
      />
    )
  }
}


export default withContext(ImageLoader);