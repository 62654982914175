import React, { Component } from 'react';

import './index.css';
import DiagonalSection from '../../components/DiagonalSection';

const config = {
  orientation: "left",
  title: "Création",
  src: `public/static/creation_section.jpg`,
  identifier: "section--creation",
  bulletPoints: [
    {
      content: {
        icon: "humaine.png",
        before: "Créer des images avec une dimension",
        loop: ["artistique", "humaine"],
        after: "",
      }
    },
    {
      content: {
        icon: "beau.png",
        before: "Rendre plus",
        loop: ["beau", "émouvant", "drôle"],
        after: "",
      }
    },
    {
      content: {
        icon: "demarquer.png",
        before: "Se démarquer à travers un",
        loop: ["autre", "nouveau"],
        after: "regard",
      }
    },
    {
      content: {
        icon: "musique.png",
        before: "Associer des idées visuelles à la musique qui va bien",
        loop: [],
        after: "",
      }
    },
  ],  
  delimiter: {
    top: {
      present: true,
      style: "reverse",
    },
    bottom: {
      present: false,
    }
  },
};

class CreationSection extends Component {
  render() {
    return (
      <DiagonalSection config={config} isDiag/>
    )
  }
}

export default CreationSection;






