import React, { Component } from 'react';
import Loader  from 'react-loader-spinner';

export default class CustomLoader extends Component {
  render() {
    return (
      <div className="w-100 h-100 d-flex p-3" style={{ verticalAlign: "middle", justifyContent: "center"}}>
            <Loader 
              type="Triangle"
              color="#FA822C"
              height="150"	
              width="150"
            />  
      </div>

    )
  }
}