import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import TextLoader from '../TextLoader';
import Slide from 'react-reveal/Slide';
import ImageLoader from '../ImageLoader';

import './index.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DEFAULT_STATE = {
    team: "public/static/team.png",
    arrowUp: BASE_URL + "public/static/arrow-up.png",
    arrowDown: BASE_URL + "public/static/arrow-down.png"
}

class CommonTeamCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_STATE
        }
    }

    render() {
        return (
            <Row>
                <Col xs={{ offset: 1, size: 10 }} sm={{ offset: 2, size: 8 }} md={{ offset: 0, size: 12 }} className="px-lg-5 mt-sm-3">
                    <div className="py-2">
                        <Row className="common-team-card mx-md-0">
                            <Col md="12" sm="12" xs="12" className="align-self-center text-left">
                                <Slide left>
                                    <div className="h3-fluid" style={{ color: "#FA822C"}}>
                                        {this.props.name2}
                                    </div>
                                    <div className="p-fluid">
                                        <TextLoader 
                                            default={this.props.role2}
                                            identifier="role--guillaume"
                                        />
                                    </div>
                                    <p className="m-0">
                                        <TextLoader 
                                            default={this.props.mail2}
                                            identifier="contact-mail--guillaume"
                                        />
                                    </p>
                                </Slide>
                            </Col>
                        </Row>
                        <Row className="my-3">
                            <Col md="3" sm="3" xs="3">
                                <img src={this.state.arrowDown} className="img-fluid" alt="arrow down" />
                            </Col>
                            <Col md="6" sm="6" xs="6" className="align-self-center">
                                <ImageLoader
                                    alt="équipe de La Mandarine"
                                    default={this.state.team}
                                    classes="img-fluid rounded-circle"
                                    identifier="team"
                                />
                            </Col>
                            <Col md="3" sm="3" xs="3" className="align-self-end" >
                                <img src={this.state.arrowUp} className="img-fluid" alt="arrow down" />
                            </Col>
                        </Row>
                        <Row className="common-team-card mx-md-0">
                            <Col md="12" sm="12" xs="12" className="align-self-center text-right">
                                <Slide left>
                                    <div className="h3-fluid" style={{ color: "#FA822C"}}>
                                        {this.props.name1}
                                    </div>
                                    <div className="p-fluid">
                                        <TextLoader 
                                            default={this.props.role1}
                                            identifier="role--bruno"
                                        />
                                    </div>
                                    <p className="m-0">
                                        <TextLoader 
                                            default={this.props.mail1}
                                            identifier="contact-mail--bruno"
                                        />
                                    </p>
                                </Slide>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }

}

export default CommonTeamCard;