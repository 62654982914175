import React, { Component } from 'react';
import { Row, Container, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import AnchorLink from 'react-anchor-link-smooth-scroll'
import FontAwesome from 'react-fontawesome';
import Bounce from 'react-reveal/Bounce';
import Slide from 'react-reveal/Slide';
import TextLoader from '../../components/TextLoader';
import ImageLoader from '../../components/ImageLoader';

import './index.css';

import config from '../../config.json';

const date = new Date();

class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <Container fluid style={{ backgroundColor: "#FB964D" }}>
          <div className="arrow-border-wrapper mx-auto">
            <div className="arrow-border mx-auto">
            </div>
          </div>
        </Container>
        <Container style={{backgroundColor: "#353969"}} fluid={true} className="py-5">
            <Row className="my-3 my-md-4 my-lg-5">
              <Col xs="12" sm="5" md="4" xl="5" className="align-self-center mx-auto text-center text-sm-right mb-3 mb-sm-0">
                <Slide left> 
                  <div className="h3-fluid text-light" style={{ textDecoration: "underline #353969"}}>
                    Rejoignez-nous
                  </div>
                  <div className="p-fluid" style={{ color: "#AFAFAF" }}>
                    et suivez notre <span style={{ color :"#fcaa6e"}}>actualité</span>
                  </div>
                </Slide>
               </Col>
              <Col sm="7" md="8" xl="7">
                <Bounce bottom cascade>
                  <ul className="ul-socials">
                    <li>
                      <a 
                        href="https://vimeo.com/lamandarine" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-vimeo" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a 
                        href="https://www.youtube.com/user/lamandarine" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-youtube" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a 
                        href="https://www.linkedin.com/in/lamandarine/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a 
                        href="https://www.facebook.com/ProductionaudiovisuelleAgenceConseilCommunication/" 
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </Bounce>
              </Col>
            </Row>
        </Container>
        <Container style={{ backgroundColor: "#131425" }} fluid className="pt-3 pt-sm-5 pb-sm-3">
          <Row className="text-white py-3">
            <Col xs="12" sm="6" md="5" lg="4" xl="4" className="text-center text-sm-right order-3 order-sm-1 py-2">
              <Slide left>
                <Row className="">
                  <Col md="3" lg="3" xl="3" className="pr-0 d-none d-md-flex text-right">
                    <ImageLoader
                      alt="Logo de La Mandarine"
                      default="public/static/logo-square.png"
                      classes="img-fluid align-self-center"
                      identifier="logo-square"
                    />
                  </Col>
                  <Col md="9" lg="9" xl="9">
                    <div className="h2-fluid site-title my-2">
                      <TextLoader 
                        default={config.general.mandarineLong}
                        identifier="site-title"
                      />
                    </div>
                    <div className="small-fluid text-light"> © {date.getFullYear()} - Tous droits réservés</div>
                  </Col>
                </Row>
              </Slide>
            </Col>
            <Col xs="12" sm="6" md="4" lg="4" xl="4" className="text-center text-sm-left footer-central-col py-md-2 order-1 order-sm-2">
              <Slide right>
              <div className="p-fluid footer-central-header mb-1">
                {config.contact.title}
              </div>
              <div className="text-light small-fluid">
                {config.contact.adress.number}{config.contact.adress.street}{config.contact.adress.zipcode} <span style={{ color: "#fcaa6e" }}>{config.contact.adress.city}</span>{config.contact.adress.country}
              </div>
              <div className="small-fluid">
                <a href="mailto:webmaster@lamandarine.com" style={{ color: "#fcaa6e" }}>
                  <TextLoader 
                    default={config.contact.mail}
                    identifier="contact-mail"
                  />
                </a>
              </div>
              </Slide>
            </Col>
            <Col xs={{ offset: 4, size: 4 }} style={{ backgroundColor: "#FA822C"}} className="order-2 my-3 d-sm-none"></Col>
            <Col xs="12" md="3"lg="4" xl="4" className="text-center order-4 order-md-3 align-self-center">
              {
                this.props.linkTo !== "homepage" ?
                (
                <Row className="mt-3 mt-md-0">
                  <Col xs="12" md="12" lg="8" xl="8" className="text-center text-lg-right align-self-center order-2 order-lg-1">
                    <p className="text-light text-uppercase my-auto mt-1">Retour en haut</p>
                  </Col>
                  <Col xs="12" md="12" lg="4" xl="4" className="text-center text-lg-left mt-2 mt-md-0 pl-lg-0 order-1 order-lg-2">
                    <AnchorLink href='#homepage'>
                      <FontAwesome name="angle-double-up" className="circle-icon"/>
                    </AnchorLink>
                  </Col>
                </Row>
                )
                :
                (
                  <Row className="mt-3 mt-md-0">
                    <Col xs="12" md="12" lg="8" xl="8" className="text-center text-lg-right align-self-center order-2 order-lg-1">
                      <p className="text-light text-uppercase my-auto mt-1">Retour à l'accueil</p>
                    </Col>
                    <Col xs="12" md="12" lg="4" xl="4" className="text-center text-lg-left mt-2 mt-md-0 pl-lg-0 order-1 order-lg-2">
                      <Link to='/'>
                        <FontAwesome name="home" className="circle-icon" style={{ padding: "0.45em 0.45em" }}/>
                      </Link>
                    </Col>
                  </Row>
                )
              }
            </Col>
          </Row>
          </Container>
        <Container fluid style={{ color: "whitesmoke" }}>
          <Row className="py-2">
            <Col size="12" className="text-right">
              <div className="small-fluid">
                Site développé par&nbsp;
                <a className="credit-link" href="https://www.linkedin.com/in/antoine-demonchaine/" target="_blank" rel="noopener noreferrer">
                  A. Demon
                </a>
                ,&nbsp;
                <a className="credit-link" href="https://www.linkedin.com/in/augustin-de-la-taille-273a4a14a/" target="_blank" rel="noopener noreferrer">
                  A. De La Taille 
                </a>
                &nbsp;&&nbsp;
                <a className="credit-link" href="https://www.linkedin.com/in/georges-cosson/" target="_blank" rel="noopener noreferrer">
                G. Cosson
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Footer;






