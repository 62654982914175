import React, { Component } from 'react';
import StraightSection from '../../components/StraightSection';

import './index.css';

const config = {
  orientation: "right",
  title: "Post Production",
  src: `public/static/postprod_section.jpg`,
  identifier: "section--postprod",
  bulletPoints: [
    {
      content: {
        icon: "monter.png",
        before: "Monter",
        loop: ["Image", "Son"],
        after: "",
      }
    },
    {
      content: {
        icon: "baguette.png",
        before: "",
        loop: ["Créer", "Exécuter"],
        after: "des SFX (trucages, compositing)",
      }
    },
    {
      content: {
        icon: "videodesign.png",
        before: "Créer du",
        loop: ["Vidéo", "Sound"],
        after: "Design",
      }
    },
    {
      content: {
        icon: "master.png",
        before: "Produire",
        loop: ["master vidéo", "version de validation"],
        after: "",
      }
    }
  ],  
  delimiter: {
    top: {
      present: true,
      style: "reverse",
    },
    bottom: {
      present: false,
    }
  },
};

class PostProdSection extends Component {

  render() {
    return (
        <StraightSection config={config}/>
    )
  } 
}

export default PostProdSection;