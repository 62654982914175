import React, { Component } from 'react';

import './index.css';
import DiagonalSection from '../../components/DiagonalSection';

const config = {
  orientation: "right",
  title: "Conseil",
  src: `public/static/conseil_section.jpg`,
  identifier: "section--conseil",
  bulletPoints: [
    {
      content: {
        icon: "touchercible.png",
        before: "",
        loop: ["Intégrer", "Anticiper"],
        after: "les tendances pour mieux toucher vos cibles",
      }
    },
    {
      content: {
        icon: "fraicheur.png",
        before: "Apporter",
        loop: ["fraicheur", "simplicité"],
        after: "à vos messages",
      }
    },
    {
      content: {
        icon: "comprendre.png",
        before: "",
        loop: ["Intégrer", "Comprendre"],
        after: "vos enjeux stratégiques, politiques, culturels et sociétaux",
      }
    },
    {
      content: {
        icon: "camera.png",
        before: "Communiquer en",
        loop: ["interne", "externe"],
        after: "en France et à l'international",
      }
    },
  ],
  delimiter: {
    top: {
      present: true,
      style: "reverse",
    },
    bottom: {
      present: false,
    }
  },
};

class CreationSection extends Component {
  render() { 
    return (
      <DiagonalSection config={config} isDiag/>
    )
  }
}

export default CreationSection;






