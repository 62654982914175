import React, { Component } from 'react';
import { Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import './index.css';
import ImageLoader from './../ImageLoader/index';

class DiagonalImage extends Component {

  render () {
    const side = this.props.imageSide === "right" ? "img-triangle" : 'img-reverse-triangle';
    
    const sideOfReveal = (this.props.imageSide === "right") ? { right: true } : { left : true };
    return (
      <Col md="6" lg="6" className='px-sm-0 d-none d-md-block align-self-center'>
        <Fade {...sideOfReveal}>
          <ImageLoader
            classes={`${side} img-fluid`}
            default={this.props.src} 
            alt={this.props.alt}
            identifier={this.props.identifier}
          />
        </Fade>
      </Col>
    )
  }
}

export default DiagonalImage;






