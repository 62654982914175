import React, { Component } from 'react';

import PresentationSection from '../PresentationSection';
import VideoSection from '../VideoSection';
import LandingSection from '../LandingSection';
import CreationSection from '../CreationSection';
import ConseilSection from '../ConseilSection';
import ProductionSection from '../ProductionSection';
import PriseVueSection from '../PriseVueSection';
import PostProdSection from '../PostProdSection';
import ReferenceSection from '../ReferenceSection';
import Footer from '../Footer';

import './index.css';

class Homepage extends Component {
  render() {
    return (
      <div className="homepage" id="homepage">
      {/* {(process.env.NODE_ENV !== "production") &&
          <div style={{ color: "white", position: "fixed", top: "5%", left: "5%", zIndex: "9999" }}>
            <p className="d-none d-xl-block">XL</p>
            <p className="d-none d-lg-block d-xl-none">LG</p>
            <p className="d-none d-md-block d-lg-none">MD</p>
            <p className="d-none d-sm-block d-md-none">SM</p>
            <p className=" d-sm-none">XS</p>
          </div>
      } */}
 
        <LandingSection />
        <PresentationSection />
        <VideoSection />
        <ConseilSection />
        <CreationSection />
        <ProductionSection />
        <PriseVueSection />
        <PostProdSection />
        <ReferenceSection />
        <Footer />
      </div>
    )
  }
}

export default Homepage;