import React, { Component } from 'react';
import TextLoop from "react-text-loop";
import { Row, Col } from 'reactstrap';
import './index.css';

class TextLine extends Component {
  render() {
    const { content } = this.props;
    let count = 0;
    return (
      <Row className="text-line my-3">
        <Col xs="2" className="">
          <div className="icon-circle my-auto">
            <img 
              className="bullet-icon img-fluid" 
              src={`${process.env.REACT_APP_BASE_URL}public/static/${content.icon}`} 
              alt="icon"
            />
          </div>
        </Col>
        <Col xs="10" className="d-flex">     
          <div className="align-self-center">
            {content.before && content.before + " "}
            <TextLoop  springConfig={{ stiffness: 100, damping: 13 }} interval={3000} delay={this.props.delay}>
              {content.loop.map(e => <span className="p-fluid" key={count++}>{e}</span>)}
            </TextLoop>
            {content.after && " " + content.after}
          </div>   
        </Col>
      </Row>
    )
  }
} 

export default TextLine;






