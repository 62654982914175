import React, { Component } from 'react';
import { Collapse, Container } from 'reactstrap';
import './index.css';
import VideoThumbnail from '../VideoThumbnail';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import VideoHighlight from '../VideoHighlight';
import FontAwesome from 'react-fontawesome';

const ArrowLeft = <FontAwesome name="angle-double-left" className="arrows circle-icon"/>;
const ArrowRight = <FontAwesome name="angle-double-right" className="arrows circle-icon"/>;

class VideoCarousel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      videos: props.videos,
      rndID : Math.floor(Math.random() * 10000) + 1,
    };
    this.onClick = this.onClick.bind(this);
  };

  onClick = id => {
    const video = this.state.videos.filter((video) => video._id === id)[0];

    const prevVideo = this.state.highlighted;

    if(video === prevVideo) this.setState({ showHighlight: !this.state.showHighlight });
    else this.setState({ highlighted: video, showHighlight: true });
  }

  onSelect = id => {
    const video = this.state.videos.filter((video) => video._id === id)[0];

    const prevVideo = this.state.highlighted;

    if(video === prevVideo) this.setState({ showHighlight: !this.state.showHighlight });
    else this.setState({ highlighted: video, showHighlight: true });
  }
  
  constructMenu = (videos, state) => videos.map(video => {
    const selected = (video === state.highlighted && state.showHighlight) ? true : false;
    return <VideoThumbnail 
            selected={selected} 
            key={video._id} 
            video={video} 
          />
  });

  onUpdate = e=> {
    // console.log(e);
  }

  render() {
    const showHL = (this.state.highlighted && this.state.showHighlight);
    const { highlighted, rndID } = this.state;
    const menu = this.constructMenu(this.state.videos, this.state);

    return (
      <div>
        <ScrollMenu 
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          selected={highlighted}
          onSelect={this.onSelect}
          menuClass="container-fluid my-5 px-0 mb-2"
          wrapperClass={`row carousel-wrapper carousel-wrapper--${rndID}`}
          itemClass="col-xs-12 col-sm-10 col-md-9 col-lg-6 col-xl-5 my-0 py-1"
          clickWhenDrag={false}
          wheel={false}
          hideArrows
          // hideSingleArrow
          onUpdate={this.onUpdate}
        />

        <Container fluid={true} className="video-carousel px-0"> 
          {
          <div>
            <Collapse isOpen={showHL} className="px-0" style={{ backgroundColor : "#FA822C"}}>
            <div className="arrow-border-wrapper mx-auto">
              <div className="arrow-border mx-auto" style={{ backgroundColor: "#07080F"}}>
              </div>
            </div>
              <VideoHighlight id="video-highlight" video={this.state.highlighted}/>
            </Collapse>
          </div>
          }
        </Container>
      </div>
    )
  }
}

export default VideoCarousel;






