import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import Jump from 'react-reveal/Jump';
import TextLoader from "../../components/TextLoader"
import ClientMarquee from '../../components/ClientMarquee';
import Delimiter from '../../components/Delimiter';

import './index.css';

class ReferenceSection extends Component {
  render() {
    return (
      <div id="reference-section">
        <Delimiter reverse="reverse"/>
        <Container style={{backgroundColor: "#091119"}} fluid={true}>
          <Jump>
            <Row className="py-5">
              <Col md="12" className="text-center">
                <div>
                  <span className="section-title" style={{ color: "whitesmoke" }}>
                    Clients
                  </span>
                  <h5 className="text-center" style={{ color: "rgb(153, 153, 153)" }}>
                    <TextLoader 
                        default="Ils nous ont fait confiance"
                        identifier="label--clients"
                    />
                  </h5>
                </div>
              </Col>
              <ClientMarquee/>
            </Row>
          </Jump>
        </Container>
      </div>
    )
  }
}

export default ReferenceSection;






