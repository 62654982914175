import React from 'react';

export const Context = React.createContext({
  texts: [],
  images: [],
})

export const withContext = (Component) => {
	return class extends React.Component {
		render = () => (
				<Context.Consumer>
					{this.contextConsumer}
				</Context.Consumer>
			)

		contextConsumer = (ctx) => {
			return <Component ctx={ctx} {...this.props} />
		}
	}
}