import React, { Component } from 'react';
import { Container } from 'reactstrap';

import ImageLoader from '../../components/ImageLoader';

import './index.css';

class Banner extends Component {
  render() {
    return (
      <Container id="banner" fluid className="px-0">
        <ImageLoader
            classes="img-fluid mx-auto"
            default="public/static/banner.jpg"
            alt="banière de la mandarine"
            identifier="banner"
        />
      </Container>
    )
  }
}

export default Banner;
 





