import React, { Component } from 'react';
import axios from 'axios';
import VideoCarousel from '../VideoCarousel';
import Slide from 'react-reveal/Slide';

import CatListPlaceholder from '../CatListPlaceholder';

import './index.css';

const DEFAULT_STATE = {
  categories: {},
  ready: false,
};

const mergeCatsAndVideos = (cats, catInfos) => {
  const noCat = { 
    _id: "nocatid",
    title: "Autre",
    description: "Vidéos non classifiées"
  };
  return cats.map(cat => {
    const infos = (cat[0] !== 'undefined') ? catInfos.find(info => info._id === cat[0]) : noCat;
    return { 
      ...infos, 
      videos: cat[1] 
    };
  });
}

class VideoByCatList extends Component {

  constructor() {
    super();
    this.state = DEFAULT_STATE;
  };

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}category?`)
    .then((res) => {
      const allCats = res.data.data.categories;
      axios.get(`${process.env.REACT_APP_API_URL}videos?groupby=category`)
      .then(response => response.data.data)
      .then(data =>  {
        const cats = Object.entries(data.categories);
        const categories = mergeCatsAndVideos(cats, allCats);
        this.setState({
          categories,
        });
      })
      .catch(err => console.log(err));
    })
    .catch(err => console.log(err));
  }

  render() {
    const cats = this.state.categories;
    const catsExists = cats.length > 0;
    return (
      <div className="videos-section py-3">
          {catsExists ? (
              cats.map((cat) => {
                return (
                    <div key={cat._id} id={"id_" + cat._id} className="video-row mb-4 text-center">
                      <Slide right>
                        <h3 className="pt-2 pl-3 cat-title">{cat.title}</h3>
                      </Slide>
                      <Slide left>
                        <h5 style={{color: "rgb(246, 167, 110)"}} className="pl-3">{cat.description}</h5>
                      </Slide>
                      <VideoCarousel videos={cat.videos}/>
                    </div>
                )
              })
            ) : (
              <div className="placeholders">
                <CatListPlaceholder videos={4}/>
                <CatListPlaceholder videos={4}/>
              </div>
            )
          }
      </div>
    );
  }
}

export default VideoByCatList;






