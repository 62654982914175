import React, { Component } from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { Context } from "../../context"
import axios from "axios";

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import Homepage from '../Homepage';
import VideosPage from '../VideosPage';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      images: [],
      texts: [],
    }
  }
  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}content/multimedia`)
      .then(res => res.data.images)
      .then(images => {
        this.setState({
          images,
        })
      })
    axios.get(`${process.env.REACT_APP_API_URL}content/textual`)
      .then(res => res.data.texts)
      .then(texts => {
        this.setState({
          texts,
        })
      })
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        <div className="App"> 
          <BrowserRouter>
            <div>
              <Route path="/" exact component={Homepage} />
              <Route path="/videos" component={VideosPage} />
            </div>
          </BrowserRouter> 
        </div>
      </Context.Provider>
      );
  }
}
  
export default App;
  