import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import 'react-circular-progressbar/dist/styles.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import './index.css';

import config from '../../config.json';
import StatsCounter from '../StatsCounter';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DEFAULT_STATE = {
  ready: false,
  source: BASE_URL + "public/static/youtube-logo4.png",
};

class LinkSection extends Component {

  constructor() {
    super();
    this.state = DEFAULT_STATE;
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}content/textual`)
      .then(res => res.data.texts)
      .then(texts => {
        const YOUTUBE_SUBS = texts.find(t => t.identifier === "youtube--subs").value
        const YOUTUBE_VIEWS = texts.find(t => t.identifier === "youtube--views").value
        const YOUTUBE_VIDEOS = texts.find(t => t.identifier === "youtube--videos").value
        this.setState({
          YOUTUBE_SUBS,
          YOUTUBE_VIDEOS,
          YOUTUBE_VIEWS,
          ready: true,
        });
      })
      .catch(e => console.log(e));
  }

  render() {
    return (
      <Row className="my-3">
          <Col xs="12" sm="5" className="text-center my-auto">
            <Fade right>
              <Row className="d-flex d-sm-none py-3">
                {/* mobile */}
                <Col xs="6" sm="12" md="6"  className="">
                  <div className="p-fluid text-right" style={{ color: "whitesmoke" }}>
                    Rejoignez-nous sur YouTube
                  </div>
                </Col>
                <Col xs="3" sm="12" md="6" className="text-center">
                  <a 
                    href={config.general.youtubeUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <img src={this.state.source} className="img-fluid" alt="Youtube" />
                  </a>
                </Col>
              </Row>
              { /* xs+ */}
              <Row>
                <Col sm="8" md="9"  className="my-auto d-none d-sm-flex">
                  <div className="p-fluid text-right my-auto w-100" style={{ color: "whitesmoke" }}>Rejoignez-nous sur YouTube</div>
                </Col>
                <Col sm="4" md="3"  className="text-left my-auto d-none d-sm-flex">
                  <a href={config.general.youtubeUrl}><img src={this.state.source} className="img-fluid" alt="Youtube" /></a>
                </Col>
              </Row>
            </Fade>
          </Col>
          <Col xs="12" sm="2" md="2" className="my-auto" >
            <StatsCounter
              number={this.state.YOUTUBE_VIDEOS}
              message="Vidéos"
            />
          </Col>
          <Col xs="12" sm="2" md="2"  className="my-auto">
            <StatsCounter
              number={this.state.YOUTUBE_SUBS}
              message="Abonnés"
            />
          </Col>
          <Col xs="12" sm="3" md="3" className="my-auto">
            <StatsCounter
              number={this.state.YOUTUBE_VIEWS}
              message="Vues"
            />
          </Col>
      </Row >
    )
  }
}

export default LinkSection;






