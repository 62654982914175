import React, { Component } from 'react';
import { withContext } from "../../context";
class TextLoader extends Component { 
  render() {
    const { identifier, ctx } = this.props
    const { texts } = ctx
    const label = ctx.texts.length > 0 ?
                    texts.find(t => t.identifier === identifier) ?
                        texts.find(t => t.identifier === identifier).value
                      :
                        this.props.default
                  :
                    this.props.default
    return (
      <span>
        {label}
      </span>
    )
  }
}


export default withContext(TextLoader);