import React, { Component } from 'react';
import { Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import './index.css';
import TextLine from '../TextLine';

class DiagonalColumn extends Component {

  render () {
    const { isDiag, orientation } = this.props;
    const updateFrequency = 2250;
    const a = this.props.bulletPoints.length;
    const b = Math.round((updateFrequency / a) / 2);
    let index = 0;
    
    const sideOfReveal = (orientation === "left") ? { right: true } : { left : true };
    const props = isDiag ?  {
      xs: "12",
      md: "6",
      className: "py-4 py-sm-4 py-md-5 d-flex",
    } : {
      xs: "12",
      md: "6",
      lg: "7",
      xl: "8",
      className: "py-4 py-sm-4 py-md-5 d-flex",
    }

    return (
        <Col {...props}>

          <div className="mx-auto px-0 text-left-sm align-self-center mt-2 px-md-3">
            <Fade {...sideOfReveal}>
              <div className="section-title">
                {this.props.title}
              </div>
              <div className="section-content">
                {
                this.props.bulletPoints.map((elem) => (
                  <TextLine
                    className="text-line"
                    content={elem.content}
                    key={index++}
                    delay={b*index}
                  />
                )
                )}
              </div>
            </Fade>
          </div>
        </Col>
    )
  }
}

export default DiagonalColumn;






