import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';

import './index.css';
import StraightImage from '../StraightImage';
import StraightColumn from '../StraightColumn';
import Delimiter from '../Delimiter';

class StraightSection extends Component { 

  render () {
    const { title, bulletPoints, src, orientation, delimiter, identifier } = this.props.config;
    return (
      <Container fluid style={{ backgroundColor: "grey"}}>
        {delimiter.top.present && <Delimiter reverse={delimiter.top.style}/> }
        {(orientation === "left") ? 
          <Row className="left-straight-section img-col py-3 py-md-0">
            <StraightImage src={src} identifier={identifier} orientation={orientation}/>
            <StraightColumn title={title} bulletPoints={bulletPoints} orientation={orientation}/>
          </Row>
        :
          <Row className="right-straight-section img-col py-3 py-md-0">
            <StraightColumn title={title} bulletPoints={bulletPoints} orientation={orientation}/>
            <StraightImage src={src} identifier={identifier} orientation={orientation}/>
          </Row>
        }
        {delimiter.bottom.present && <Delimiter reverse={delimiter.bottom.style}/> }
      </Container>
    )
  }
}

export default StraightSection;






