import React, { Component } from 'react';
import { Container } from 'reactstrap';

import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import './index.css';

class LinkSection extends Component {
  render() {
    const label = this.props.label;
    const linkTo = this.props.to;
    return (
      <Container style={{backgroundColor: ""}} fluid={true}>
          <div className="py-4 mx-auto" style={{ verticalAlign: "center"}}>
            <div className="text-center">
              <Button outline color="warning" size="lg" className="orange-outline">
                <Link to={linkTo} style={{color: "inherit", textDecoration: "none"}}>
                  <span>{label}</span>  
                </Link>
              </Button>
            </div>
          </div>
      </Container>
    )
  }
}

export default LinkSection;






