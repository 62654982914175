import React, { Component } from 'react';
import './index.css';

class VideoThumbnail extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      video: this.props.video,
    };
  }

  render() {
    const video = this.state.video;
    const baseClasses = "img-fluid video-thumbnail";
    const classes = (this.props.selected) ? baseClasses + " selected-video" : baseClasses;
    return (
      <img 
        key={video._id} 
        className={classes} 
        src={video.video.thumbnails.large} 
        alt={video.title}
        draggable="false"
      />
    );
  }
}

export default VideoThumbnail;