import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import axios from "axios";
import CustomLoader from "../CustomLoader"
import { withContext } from "../../context"
import './index.css';

// const clients = ["bnf.png", "dentsu.png", "mktg.png", "caisse-epargne.png", "sorbonne.png", "institut-curie.png", "leparfait.png", "cicc.png", "sephora.png", "CERC.png", "dontnod.png", "samsung.png", "fleury-michon.png", "michelin.png", "europe direct.png", "doc_francaise.png", "1762.png", "chassis-brakes.png"]; //, "commodafrica.png", "pole emploi.png"]; 

class ClientMarquee extends Component {

  constructor(props) {
    super(props);
  
    this.state = { rerender: false };
  }
  componentDidMount(){
    window.addEventListener('resize', this.updateDimensions);
	}
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => this.setState({ rerender: !this.state.rerender });

  getNumberOfClientsToDisplay() {
    const screenSize = window.innerWidth;
    /* xs */
    if (screenSize < 576) return 6;
    /* sm */
    if (screenSize < 768) return 8;
    /* md */
    if (screenSize < 992) return 12;
    /* lg+ */
    return 18;
  }

  render() {
    const { ctx } = this.props
    const numberOfDisplayedClients = this.getNumberOfClientsToDisplay();
    const clients = ctx.images.filter(image => image.identifier.startsWith("client-")).slice(0, numberOfDisplayedClients).map(c => c.path)
    return (
      <div className="marquee py-3">
        <Row>
          <Col xs={{ offset: 1, size: 10 }} sm={{ offset: 1, size: 10 }} xl={{ offset: 2, size: 8 }}>
            <Row className="">
              { clients.length > 0 ?
                  clients.map((c, index) => 
                    <Col xs="4" sm="3" md="2" className="px-0" style={{ backgroundColor: "#F9F9F9" }} key={index}>
                      <div className="px-3 px-md-1 px-lg-3 py-2 py-md-1">                    
                        <img 
                          src={`${process.env.REACT_APP_BASE_URL}${c}`} 
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </Col>)
                  :
                  <CustomLoader />
              }
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

}

export default withContext(ClientMarquee);