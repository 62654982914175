import React, { Component } from 'react';
import { Col } from 'reactstrap';
import ImageLoader from '../ImageLoader';

import './index.css';

class StraightImage extends Component {

  render () {
    const side = this.props.orientation === "right" ? 'left-inset-arrow' : 'right-inset-arrow';
    
    return (
      <Col xs="12" md="6" lg="5" xl="4" className="px-0 d-none d-md-block position-relative">
        <ImageLoader
          default={this.props.src}
          identifier={this.props.identifier}
          alt={this.props.alt}
          classes="img-fluid"
        />
        <div className={`${side} position-absolute`}/>
    </Col>
    )
  }
}

export default StraightImage;






