import React, { Component } from 'react';

import Footer from '../Footer';
import Banner from '../Banner';
import { Container } from 'reactstrap';

import LinkSection from '../../components/LinkSection';
import Delimiter from '../../components/Delimiter';
import VideoByCatList from '../../components/VideoByCatList';

class VideosPage extends Component {   
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="videos-pages">
        <Banner/>
        <Container>
          <Delimiter/>
        </Container>
        <LinkSection to="/" label="Accueil"/>
        <VideoByCatList/>
        <Footer linkTo="homepage"/>
      </div>
    )
  }
}

export default VideosPage;