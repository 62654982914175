import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { withContext } from "../../context";
import VideoOverlay from '../VideoOverlay';
import CustomLoader from '../CustomLoader';

import './index.css';

const DEFAULT_VIDEO = `${process.env.REACT_APP_BASE_URL}public/static/voeux.mp4`;
const IDENTIFIER = "landing-video"

class VideoWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      muted: true,
    }; 
    this.handleMuteButton = this.handleMuteButton.bind(this);
  }

  handleMuteButton(e) {
    this.setState({
      muted: !this.state.muted,
    });
  }

  render() {
    const { ctx } = this.props;
    const source = ctx.images.length > 0 ?
                        ctx.images.find(i => i.identifier === IDENTIFIER) ?
                            ctx.images.find(i => i.identifier === IDENTIFIER).path
                          :
                            DEFAULT_VIDEO
                      :
                        DEFAULT_VIDEO
                        
    return (
      <div className="video-wrapper">
        {source !== "" ? 
          <div>
            <ReactPlayer  
              url={`${process.env.REACT_APP_BASE_URL}${source}`}
              playing
              loop
              muted={this.state.muted}
              width="100%"
              height="80%"
            />
            <VideoOverlay handleMute={this.handleMuteButton} muted={this.state.muted}/>
          </div>
          :
          <CustomLoader/>
        }
      </div>
    )
  } 
} 

export default withContext(VideoWrapper);






