import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import Slide from 'react-reveal/Slide';

import StatsSection from '../../components/StatsSection';
import ContactCard from '../../components/ContactCard';
import CommonTeamCard from '../../components/CommonTeamCard';
import MapCard from '../../components/MapCard';
import TextLoader from '../../components/TextLoader';

import config from '../../config.json';

import './index.css';

class PresentationSection extends Component {
  render() {
    return (
      <div id="presentation-section" style={{ position: "relative" }}>
        <Container fluid={true} className="px-0">
          <Col md="12">
            <Row className="stats-section">
              <Col md="12" sm="12" className="d-flex my-3" style={{ color: "whitesmoke" }}>
                <div className="p-fluid align-self-center text-center w-100 mb-3">
                  <Fade right>     
                    <div className="h2-fluid">
                      <span style={{ color: "#fcaa6e" }}>
                        <TextLoader 
                          default={config.general.mandarineLong}
                          identifier="site-title"
                        />
                      </span>,
                      <span style={{ fontSize: "80%" }}>
                        <TextLoader 
                          default={config.general.presentation}
                          identifier="site-desc"
                        />
                      </span>
                    </div>
                  </Fade>
                  <Fade left>                  
                    <div className="h3-fluid mt-3 text-white">
                      {config.presentation.subtitle.word1}
                      <span style={{ color: "rgb(246, 167, 110)" }}>{config.presentation.subtitle.separator}</span>
                      {config.presentation.subtitle.word2}
                      <span style={{ color: "rgb(246, 167, 110)" }}>{config.presentation.subtitle.separator}</span>
                      {config.presentation.subtitle.word3}
                    </div>
                  </Fade>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12" sm="12">
                <StatsSection />
              </Col>
            </Row>
            <Row className="who pb-5">
              <Pulse>
                <h3 className="pt- h2-fluid text-center w-100 mt-4" style={{ color: "whitesmoke" }}>
                  {config.presentation.title}
                </h3>
              </Pulse>
            </Row>
            <Row className="team-section align-items-center my-2">
              <Col md="6" className="">
                <CommonTeamCard
                  avatar="avatar.png"
                  name1={config.team.bruno.name}
                  name2={config.team.guillaume.name}
                  role1={config.team.bruno.job}
                  role2={config.team.guillaume.job}
                  mail1={config.team.bruno.mail}
                  mail2={config.team.guillaume.mail}
                />
              </Col>
              <Col md="6" className="">
                <Slide right>
                  <MapCard />
                </Slide>
              </Col>
            </Row>
          </Col>
          <ContactCard />
        </Container>
      </div >
    )
  }
}

export default PresentationSection;






