import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import './index.css';

const colors = ["#FA822C", "#353969", "#AFAFAF"];

class Delimiter extends Component {

  render () {
    const effectiveColors =  (this.props.reverse !== "reverse") ? colors : colors.reverse();
    return (
      <Row className="py-4" style={{ backgroundColor:"#07080f" }}>
        <Col xs={{ offset: 3, size: 2 }} className="z-index-custom" style={{ height: "4px", backgroundColor: effectiveColors[0] }}/>
        <Col xs={{ size: 2 }} className="z-index-custom" style={{ height: "4px", backgroundColor: effectiveColors[1] }}/>
        <Col xs={{ size: 2 }} className="z-index-custom" style={{ height: "4px", backgroundColor: effectiveColors[2] }}/>
      </Row>
    )
  }
}

export default Delimiter;






