import React, { Component } from 'react';
import axios from 'axios';
import VideoCarousel from '../VideoCarousel';
import CustomLoader from '../CustomLoader';

import './index.css';
import config from '../../config.json';

const DEFAULT_STATE = {
  videos: [],
};

class FirstVideoFromCats extends Component {
  constructor() {
    super();
    this.state = DEFAULT_STATE;
  };

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_API_URL}videos`)
    .then(res => res.data.data.videos)
    .then(videos => videos.filter(video => video.isHomepage && video.active))
    .then(videos => {
      this.setState({
        videos,
      });
    })
    .catch(err => console.log(err));
  }

  render() {
    const videos = this.state.videos;
    const videosExists = videos.length > 0;
    return (
      <div className="video-row py-2">
        <h3 className="pt- h2-fluid text-center" style={{ color: "whitesmoke" }}>{config.projets.title}</h3>
        <h5 style={{ color: "#999999" }} className="text-center">
          {config.projets.selection}
          <span style={{ color: "#F6A76E" }}> {config.projets.videos}</span>
        </h5>
        {videosExists ? (
          <VideoCarousel videos={videos} />
        ) : (
            <CustomLoader/>
          )
        }
      </div>
    );
  }
}

export default FirstVideoFromCats;






