import React, { Component } from 'react';
import  FontAwesome  from 'react-fontawesome';
import { Row, Col } from 'reactstrap';

import './index.css';

class Socials extends Component {
  render () {
    return (
      <div id="socials">
      <div className="d-block d-sm-none my-auto xs-socials-backup">
        <a className="social-icon" href="https://vimeo.com/lamandarine">
          <div className="landing-links social-icon--vimeo">
            <FontAwesome name="vimeo"/>
          </div>
        </a>
        <a className="social-icon" href="https://www.youtube.com/user/lamandarine">
          <div className="landing-links social-icon--youtube">
            <FontAwesome name="youtube"/>
          </div>
        </a>
        <a className="social-icon" href="https://www.linkedin.com/in/lamandarine">
          <div className="landing-links social-icon--linkedin">
            <FontAwesome name="linkedin"/>
          </div>
        </a>
        <a className="social-icon" href="https://www.facebook.com/ProductionaudiovisuelleAgenceConseilCommunication/">
          <div className="landing-links social-icon--facebook">
            <FontAwesome name="facebook"/>
          </div>
        </a>
      </div>
      <div className="d-none d-sm-block social-icons w-100">
        <Row>
          <Col size="12" className="d-flex">
            <div className="mx-auto d-flex">
              <a className="social-icon" href="https://vimeo.com/lamandarine">
                        <div className="landing-links social-icon--vimeo">
                          <FontAwesome name="vimeo"/>
                        </div>
                      </a>
                      <a className="social-icon" href="https://www.youtube.com/user/lamandarine">
                        <div className="landing-links social-icon--youtube">
                          <FontAwesome name="youtube"/>
                        </div>
                      </a>
                      <a className="social-icon" href="https://www.linkedin.com/in/lamandarine">
                       <div className="landing-links social-icon--linkedin">
                          <FontAwesome name="linkedin"/>
                        </div>
                      </a>
                      <a className="social-icon" href="https://www.facebook.com/ProductionaudiovisuelleAgenceConseilCommunication/">
                        <div className="landing-links social-icon--facebook">
                          <FontAwesome name="facebook"/>
                        </div>
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
              </div>
    )
  }
}

export default Socials;