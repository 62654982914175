import React, { Component } from 'react';

class VideoPlayer extends Component {

  render() {
    return (
      <div dangerouslySetInnerHTML={{ __html: this.props.video.video.embed }}>
      </div>
    );
  }
}

export default VideoPlayer;