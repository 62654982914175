import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';

import './index.css';
import DiagonalImage from '../DiagonalImage';
import StraightColumn from '../StraightColumn';
import Delimiter from '../Delimiter';

class DiagonalSection extends Component {
  render() {
    const { title, bulletPoints, src, orientation, delimiter, identifier } = this.props.config;
    return (
      <Container fluid style={{ backgroundColor: "grey"}}>
        {delimiter.top.present && <Delimiter reverse={delimiter.top.style}/> }
        {(orientation === "left") ? 
          <Row 
            className="left-diagonal-section img-col py-3 py-md-0"
          >
            <DiagonalImage 
              src={src} 
              imageSide={orientation}
              identifier={identifier}
            />
            <StraightColumn title={title} bulletPoints={bulletPoints} isDiag={this.props.isDiag} orientation={orientation}/>
          </Row>
        :
        <Row 
          className="right-diagonal-section img-col py-3 py-md-0"
        >
          <StraightColumn title={title} bulletPoints={bulletPoints} isDiag={this.props.isDiag} orientation={orientation}/>
          <DiagonalImage 
              src={src} 
              imageSide={orientation}
              identifier={identifier}
          />
        </Row>
        }
        {delimiter.bottom.present && <Delimiter reverse={delimiter.bottom.style}/> }
      </Container>
    )
  }
}

export default DiagonalSection;






