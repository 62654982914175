import React, { Component } from 'react';

import VideoWrapper from '../../components/VideoWrapper';

import './index.css';

class Landing extends Component {

  render() {
    return (
      <div id="landing-section">
          <VideoWrapper/>
      </div>
    )
  }
}

export default Landing;
