import React, { Component } from 'react';
import StraightSection from '../../components/StraightSection';

import './index.css';

const config = {
  orientation: "left",
  title: "Prise de vue",
  src: `public/static/prisevue_section.jpg`,
  identifier: "section--prisedevue",
  bulletPoints: [
    {
      content: {
        icon: "drone.png",
        before: "",
        loop: ["Exploiter", "Maitriser"],
        after: "les nouvelles technos de prises de vue vidéo",
      }
    },
    {
      content: {
        icon: "cameraman.png",
        before: "Être",
        loop: ["rapide", "efficace"],
        after: "pour capter l’événement",
      }
    },
    {
      content: {
        icon: "weather.png",
        before: "S'",
        loop: ["intégrer", "adapter"],
        after: "à la situation",
      }
    },
    {
      content: {
        icon: "camera.png",
        before: "",
        loop: ["Diriger", "Coacher"],
        after: "les personnes filmées",
      }
    },
  ],
  delimiter: {
    top: {
      present: true,
      style: "reverse",
    },
    bottom: {
      present: false,
    }
  },
};

class PriseVueSection extends Component {

  render() {
    return (
        <StraightSection config={config}/>
    )
  } 
}

export default PriseVueSection;