import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import Socials from '../Socials';

import './index.css';
import ImageLoader from './../ImageLoader/index';

class VideoOverlay extends Component {
  render() {
    return (
      <div className="card-img-overlay" style={{color: "white"}}>
        <ImageLoader
          default="public/static/logo.png"
          identifier="logo"
          classes="fixed-logo img-fluid"
          alt="Logo de la Mandarine"
        />
        <Socials/>
        {(this.props.muted) ? 
          (<button 
            onClick={this.props.handleMute}
            className="mute-button"
            >
              <FontAwesome name="volume-off"/>
            </button>)
          :
          (<button 
            onClick={this.props.handleMute} 
            className="mute-button"
            >
              <FontAwesome name="volume-up"/>
            </button>)
        }
      </div>
    );
  }
} 

export default VideoOverlay;






