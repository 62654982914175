import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import config from '../../config.json';

import './index.css';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const DEFAULT_STATE = {
    source: BASE_URL + "public/static/Paris5.png"
}

class MapCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_STATE
        }
    }

    render() {
        return (
            <Col>
                <Row className="mb-2">
                    <Col md="12" sm="12" className="photo-section text-white text-center map-content">
                        <img src={this.state.source} 
                            alt="carte de Paris" 
                            className="photo-paris img-fluid align-content-center w-75"
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col md="12" sm="12" className="text-white text-center content-section">
                        {/* <div className="p-fluid align-self-center text-right w-100">Nous sommes situés à <span style={{ color: "#fcaa6e" }}>Paris</span> <br /> au <span style={{ color: "#fcaa6e" }}>27 rue Pierre Fontaine</span> dans le 9ème</div> */}
                        {/* <div className="p-fluid align-self-center text-center w-100">Situé au pied de Montmartre</div> */}
                        <div className="p-fluid">
                            {config.contact.phrase}{config.contact.adress.number}{config.contact.adress.street}{config.contact.adress.zipcode} <span style={{ color: "#fcaa6e" }}>{config.contact.adress.city}</span>{config.contact.adress.country}
                        </div>
                    </Col>

                </Row>
            </Col>
        )
    }

}

export default MapCard;